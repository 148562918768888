import React from 'react';
import Home from './views/Home.js';
import { HashRouter, Route, Redirect } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.userRedirect = this.userRedirect.bind(this);

    this.state = {
      isShowingHeader: false,
    };
  }

  userRedirect(nextState, replace) {
    var language = window.navigator.userLanguage || window.navigator.language;
    var defaultLanguage = (language && language.substring(0, 2) === 'pt')
      ? 'pt-br'
      : 'en'
    var redirectPath = `${defaultLanguage}${nextState.location.pathname}`
    return (
      <Redirect to={redirectPath} />
    )
  };

  render () {
    return (
      <LocalizeProvider>
        <HashRouter>
          <div className="App">
            <Route path="/:lang/" exact component={Home} />
            <Route path="/" exact render={this.userRedirect} />
          </div>
        </HashRouter>
      </LocalizeProvider>
    );
  }
}

export default App;
