import React from 'react';


import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'ionicons/dist/css/ionicons.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import '../assets/template/style.css';
import '../assets/template/css/responsive.css';
import 'magnific-popup/dist/magnific-popup.css';



import '../assets/template/js/jquery-loader';
import 'bootstrap/dist/js/bootstrap.bundle';

import Header from './Home/Header.js';
import Welcome from './Home/Welcome.js';
import About from './Home/About.js';
import Contact from './Home/Contact.js';
import Footer from './Home/Footer.js';
import Video from './Home/Video.js';
import Metrics from './Home/Metrics.js';
import Benefits from './Home/Benefits.js';
import { renderToStaticMarkup } from "react-dom/server";

import globalTranslations from "../translations/global.json";
import { withLocalize } from "react-localize-redux";



class Home extends React.Component {

  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Portuguese", code: "pt-br" }
      ],
      translation: globalTranslations,
      options: { 
        renderToStaticMarkup,
        onMissingTranslation: ({ defaultTranslation }) => defaultTranslation,
      }
    });
    const firstPathName = this.props.location.pathname.split('/')[1];
    this.props.setActiveLanguage(firstPathName);
  }

  render() {
    return (
      <div className="Home">
        {/* <div id="preloader">
            <div class="colorlib-load"></div>
        </div> */}
        <Header/>
        <Welcome/>
        <About/>
        <Video />
        <Metrics />
        <Benefits />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default withLocalize(Home);
