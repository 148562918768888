
import React from 'react';
import solutionImg from '../../assets/img/solutions8.png';
import { Translate } from "react-localize-redux";

class Welcome extends React.Component {

  render() {
    return (
      <div className="Welcome">
          <section className="wellcome_area clearfix" id="home">
          <div className="container h-100">
              <div className="row h-100 align-items-center">
                  <div className="col-12 col-md">
                      <div className="wellcome-heading">
                          <h2>Engbyte</h2>
                          {/* <h3>∞</h3> */}
                          
                          <h5><Translate id="welcome.title" /></h5>
                      </div>
                      <div className="get-start-area">
                          {/* <!-- Form Start --> */}
                          {/* <form action="#" method="post" className="form-inline">
                              <input type="email" className="form-control email" placeholder="name@company.com"/>
                              <input type="submit" className="submit" value="Contact Us Started"/>
                          </form> */}
                          {/* <!-- Form End --> */}
                      </div>
                  </div>
              </div>
          </div>
          {/* <!-- Welcome thumb --> */}
          <div className="welcome-thumb wow fadeInDown" data-wow-delay="0.5s">
              <img src={solutionImg} alt=""/>
          </div>
      </section>
      </div>
    );
  }
}


export default Welcome;
