/* eslint-disable no-undef */
import React from "react";
import '../../assets/template/js/one-page-nav';
import { Translate } from "react-localize-redux";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      isShowingHeader: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    $('#nav').onePageNav({
      currentClass: 'active',
      scrollSpeed: 2000,
      easing: 'easeOutQuad'
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  showHeader() {
    $('.header_area').addClass('sticky slideInDown');
    this.setState({
      isShowingHeader: true,
    });
  }

  hideHeader() {
    $('.header_area').removeClass('sticky slideInDown');
    this.setState({
      isShowingHeader: false,
    });
  }

  handleScroll(event) {
    if (event.target.scrollingElement.scrollTop > 48) {
      this.showHeader();
    } else {
      this.hideHeader();
    }
  }

  render() {
    const titleInHeader = this.state.isShowingHeader && (
      <a className="navbar-brand" href="#">
        Engbyte
      </a>)
    return (
      <div className="Home">
        <header className="header_area animated">
          <div className="container-fluid">
            <div className="row align-items-center">
              {/* <!-- Menu Area Start --> */}
              <div className="col-12 col-lg-10">
                <div className="menu_area">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    {/* <!-- Logo --> */}
                    {titleInHeader}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#ca-navbar"
                      aria-controls="ca-navbar"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                    {/* <!-- Menu Area --> */}
                    <div className="collapse navbar-collapse" id="ca-navbar">
                      <ul className="navbar-nav ml-auto" id="nav">
                        {/* <li className="nav-item active">
                          <a className="nav-link" href="#home">
                            Home
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link" href="#about">
                          <Translate id="header.about" />
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#benefits">
                          <Translate id="header.benefits" />
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#contact">
                            <Translate id="header.contact" />
                          </a>
                        </li>
                      </ul>
                      {/* <div className="sing-up-button d-lg-none">
                        <a href="#">Sign Up Free</a>
                      </div> */}
                    </div>
                  </nav>
                </div>
              </div>
              {/* <!-- Signup btn --> */}
              {/* <div className="col-12 col-lg-2">
                <div className="sing-up-button d-none d-lg-block">
                  <a href="#">Sign Up Free</a>
                </div>
              </div> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
