import React from "react";
import axios from 'axios';
import { Translate, getTranslate } from "react-localize-redux";

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      bodyMsg: '',
    };
    this.changeEmail = this.changeEmail.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeBodyMsg = this.changeBodyMsg.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  changeEmail(event) {
    this.setState({email: event.target.value});
  }

  changeName(event) {
    this.setState({name: event.target.value});
  }

  changeBodyMsg(event) {
    this.setState({bodyMsg: event.target.value});
  }

  async sendEmail(event) {
    event.preventDefault();
    const url = 'https://putflag.com/en/send_email_to_cirochang';
    let bodyFormData = new FormData();
    bodyFormData.set('name', `ENGBYTE <${this.state.name}>`);
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('text', this.state.bodyMsg);
    await axios({
      method: 'post',
      url,
      data: bodyFormData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    });
    this.setState({email: '', name: '', bodyMsg: ''});
    alert('The email was successfully sent');
  }

  render() {
    return (
      <div className="Contact">
        <section
          className="footer-contact-area section_padding_100 clearfix"
          id="contact"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {/* <!-- Heading Text  --> */}
                <div className="section-heading">
                  <h2><Translate id="contact.title" /></h2>
                  <div className="line-shape" />
                </div>
                <div className="footer-text">
                  <p>
                    <Translate id="contact.body" />
                  </p>
                </div>
                <div className="address-text">
                  <p>{/* <span>Address:</span> Rio de Janeiro, BR */}</p>
                </div>
                <div className="phone-text">
                  <p>
                    <span><Translate id="contact.phone"/>:</span>
                    +55 21 967507800
                  </p>
                </div>
                <div className="email-text">
                  <p>
                    <span>Email:</span> contact@engbyte.com
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                {/* <!-- Form Start--> */}
                <div className="contact_from">
                  <form onSubmit={this.sendEmail}>
                    {/* <!-- Message Input Area Start --> */}
                    <div className="contact_input_area">
                      <div className="row">
                        {/* <!-- Single Input Area Start --> */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <Translate>
                            {({ translate }) => (
                              <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              placeholder={translate("contact.form.name")}
                              value={this.state.name}
                              onChange={this.changeName}
                              required
                              />
                            )}
                            </Translate>
                          </div>
                        </div>
                        {/* <!-- Single Input Area Start --> */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <Translate>
                            {({ translate }) => (
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder={translate("contact.form.email")}
                                value={this.state.email}
                                onChange={this.changeEmail}
                                required
                              />
                            )}
                            </Translate>
                          </div>
                        </div>
                        {/* <!-- Single Input Area Start --> */}
                        <div className="col-12">
                          <div className="form-group">
                          <Translate>
                          {({ translate }) => (
                            <textarea
                              name="message"
                              className="form-control"
                              id="message"
                              cols="30"
                              rows="4"
                              placeholder={translate('contact.form.bodyMsg')}
                              value={this.state.bodyMsg}
                              onChange={this.changeBodyMsg}
                              required
                            />
                          )}
                          </Translate>
                          </div>
                        </div>
                        {/* <!-- Single Input Area Start --> */}
                        <div className="col-12">
                          <button type="submit" className="btn submit-btn">
                            <Translate id="contact.form.sendBtn"/>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Message Input Area End --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
