/* eslint-disable no-undef */
import React from "react";
import videoPhoto from "../../assets/img/agiledev.jpg";
import "react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";

class Video extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }
  
  hideModal() {
    this.setState({ isModalOpen: false });
  }

  render() {      
    return (
      <div className="Video">
        <div className="video-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <!-- Video Area Start --> */}
                <div
                  className="video-area"
                  style={{ backgroundImage: `url(${videoPhoto})` }}
                >
                  <div className="video-play-btn">
                    <a className="video_btn" onClick={this.openModal}>
                      <i className="fa fa-play" aria-hidden="true" />
                    </a>
                  </div>
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isModalOpen}
                    videoId="WK36iIpnq2s"
                    onClose={this.hideModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
