/* eslint-disable no-undef */

import React from "react";
import '../../assets/template/js/footer-reveal.min.js';


class Footer extends React.Component {

  componentDidMount() {
    $('.Footer').footerReveal({
        shadow: true,
        shadowOpacity: 0.3,
        zIndex: -101
    });
  }

  render() {
    return (
      <div className="Footer">
        {/* <div id="preloader">
            <div className="colorlib-load"></div>
        </div> */}
        <footer className="footer-social-icon text-center section_padding_70 clearfix">
          {/* <!-- footer logo --> */}
          <div className="footer-text">
            <h2>Engbyte</h2>
          </div>
          {/* <!-- social icon--> */}
          <div className="footer-social-icon">
            {/* <a href="#">
              <i className="fa ti-facebook" aria-hidden="true" />
            </a> */}
            {/* <a href="#">
              <i className="fa ti-twitter" aria-hidden="true" />
            </a>
            <a href="#">
              {" "}
              <i className="fa ti-instagram" aria-hidden="true" />
            </a> */}
          </div>
          <div className="footer-menu">
            <nav>
              <ul>
                {/* <li>
                  <a href="#">About</a>
                </li> */}
                {/* <li>
                  <a href="#">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li> */}
                {/* <li>
                  <a href="#">Contact</a>
                </li> */}
              </ul>
            </nav>
          </div>
          {/* <!-- Foooter Text--> */}
          <div className="copyright-text">
            {/* <!-- ***** Removing this text is now allowed! This template is licensed under CC BY 3.0 ***** --> */}
            <p>
              Copyright ©2024 Engbyte.
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
