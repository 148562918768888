import React from "react";
import { Translate } from "react-localize-redux";

function Benefits() {
  return (
    <div className="Benefits">
      <section
        className="awesome-feature-area bg-white section_padding_0_50 clearfix"
        id="benefits"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <!-- Heading Text --> */}
              <div className="section-heading text-center">
                <h2><Translate id="benefits.title" /></h2>
                <div className="line-shape" />
              </div>
            </div>
          </div>

          <div className="row">
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-check" aria-hidden="true" />
                <h5><Translate id="benefits.box.quality.title" /></h5>
                <p>
                  <Translate id="benefits.box.quality.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-reload" aria-hidden="true" />
                <h5><Translate id="benefits.box.agile.title" /></h5>
                <p>
                  <Translate id="benefits.box.agile.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-layout" aria-hidden="true" />
                <h5><Translate id="benefits.box.simple.title" /></h5>
                <p>
                  <Translate id="benefits.box.simple.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-ruler-pencil" aria-hidden="true" />
                <h5><Translate id="benefits.box.design.title" /></h5>
                <p>
                  <Translate id="benefits.box.design.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-direction-alt" aria-hidden="true" />
                <h5><Translate id="benefits.box.contract.title" /></h5>
                <p>
                  <Translate id="benefits.box.contract.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Start --> */}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-headphone" aria-hidden="true" />
                <h5><Translate id="benefits.box.support.title" /></h5>
                <p>
                  <Translate id="benefits.box.support.description" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Benefits;
