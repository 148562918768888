import React from "react";
import { Translate } from "react-localize-redux";

function About() {
  return (
    <div className="About">
      <section className="special-area bg-white section_padding_100" id="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <!-- Section Heading Area --> */}
              <div className="section-heading text-center">
                <h2><Translate id="about.title" /></h2>
                <div className="line-shape" />
              </div>
            </div>
          </div>

          <div className="row">
            {/* <!-- Single Special Area --> */}
            <div className="col-12 col-md-3">
              <div
                className="single-special text-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="single-icon">
                  <i className="ti-mobile" aria-hidden="true" />
                </div>
                <h4><Translate id="about.box.mobile.title" /></h4>
                <p>
                  <Translate id="about.box.mobile.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Special Area --> */}
            <div className="col-12 col-md-3">
              <div
                className="single-special text-center wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div className="single-icon">
                  <i className="ti-world" aria-hidden="true" />
                </div>
                <h4><Translate id="about.box.web.title" /></h4>
                <p>
                  <Translate id="about.box.web.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Special Area --> */}
            <div className="col-12 col-md-3">
              <div
                className="single-special text-center wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <div className="single-icon">
                  <i className="ti-desktop" aria-hidden="true" />
                </div>
                <h4><Translate id="about.box.desktop.title" /></h4>
                <p>
                  <Translate id="about.box.desktop.description" />
                </p>
              </div>
            </div>
            {/* <!-- Single Special Area --> */}
            <div className="col-12 col-md-3">
              <div
                className="single-special text-center wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <div className="single-icon">
                  <i className="ti-settings" aria-hidden="true" />
                </div>
                <h4><Translate id="about.box.backend.title" /></h4>
                <p>
                  <Translate id="about.box.backend.description" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
